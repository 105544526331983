<template>
  <div class="index_11">
    <el-container direction="vertical"   hidden="true">
      <div class="header_bg">
        <el-row>
            <h4>汇通金财软件机器人管控平台</h4>
            <div class="top-right-container">
              <span class="userlogo">
                <img src="../../assets/images/top_04.png">
              </span>
              <el-dropdown>
                <span >
                  {{value1}}
                  <i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item @click.native="logout" >退出系統</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
        </el-row>
      </div>
      <el-container>
      <el-aside style="width:200px;">
        <Right/>
      </el-aside>
      <el-main>
        <router-view></router-view>
      </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script scoped>
import Right from '@/components/right_list'
export default {
  components: {
    Right
  },
  data () {
    return {
      container: true,
      value1: localStorage.yun_user.replace('"', '').replace('"', '')
    }
  },
  methods: {
    logout () {
      this.$router.push('/logins')
      setTimeout(() => {
        window.location.reload()
      }, 150)
    }

  }
}
</script>

<style scoped>
h4 {
  position: absolute; /* 使用绝对定位 */
  top: 22px; /* 距顶部10px */
  left: 15px; /* 距左侧10px，根据需求调整 */
  letter-spacing: 1px;
  text-align: left; /* 文本左对齐 */
  font-size: 1.25vw; /* 使用视口单位调整字体大小 */
  color: rgb(253, 253, 253);
  margin: 0; /* 移除外边距 */
  max-width: 90%; /* 保持最大宽度，根据实际布局需要可能调整或移除 */
}

@media (max-width: 768px) {
  h4 {
    font-size: 2vw; /* 在较小屏幕上增大字体大小 */
  }
  .el-col div {
    margin-left: 10px; /* 调整间距 */
    width: auto; /* 或其他适当值 */
  }
  img {
    width: 50%; /* 在小屏幕上增大图片的大小 */
    height: auto; /* 保持图片的原始宽高比 */
    margin-left: 10px; /* 减小左边距 */
  }
}
.top-right-container {
  display: flex; /* 启用 Flexbox 布局 */
  align-items: center; /* 垂直居中对齐子元素 */
  justify-content: flex-end; /* 子元素向右对齐 */
  position: absolute; /* 或 fixed，根据需求 */
  top: 10px; /* 距离顶部的距离 */
  right: 10px; /* 距离右侧的距离 */
}
.userlogo {
  position: absolute; /* 或者使用 fixed，根据需求 */
  top: 15px; /* 靠上10px */
  right: 2px; /* 靠右2px */
  width: 2vw; /* 宽度设置为视口宽度的百分比 */
  height: auto; /* 高度自动以保持比例 */
  display: block; /* 块级显示 */
}
.userlogo img {
  width: 100%; /* 图片宽度填充整个父容器 */
  height: auto; /* 高度自动调整以保持图片比例 */
}

.el-dropdown {
  font-size: 15px; /* 字体大小，根据需要调整 */
  color: white; /* 字体颜色 */
  margin-left: 10px; /* 与.userlogo img之间的间距 */
  top: 22px; /* 靠上10px */
  right: 43px;
}

.el-header,

.el-aside {
  background-color: #fcfcfc;
  color: rgb(197, 188, 188);
  text-align: center;
  line-height: 300px;
}

.el-main {
  background-image:url('../../assets/images/index_bj.jpg');
  background-repeat:no-repeat;
  background-size:100% 100%;
  -moz-background-size:100% 100%;
}

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

.el-container{
  height: 100vh; /* 使高度等于视口的100% */
  width: 100vw; /* 使宽度等于视口的100% */
  max-width: 100%; /* 确保宽度不超过视口宽度 */
  max-height: 100%; /* 确保高度不超过视口高度 */
  overflow: hidden; /* 防止内容溢出 */
}
.el-header{
  height: 120px !important;
  display: block;
  padding: 0;
  background:none;
  border-bottom: 2px solid rgb(253, 251, 251);
  box-sizing: border-box;
  margin-bottom: 10px;
}

.index_title img{
  display: block;
  float: left;
  padding: 15px 0 0 50px;
}
.index_exit{
    width: calc(100% - 775px);
    float: right;
    position: absolute;
    top: 0;
    right: 0;
}
.el-main{
  background-color: #fff;
}
.el-table{
  font-size: 16px;
  color: #000;
}
.el-submenu.is-active .el-submenu__title {
    border-bottom-color: #1f8f8a;
}
.el-table th>.cell{
  color: #C76362;
  font-size: 20px;
}
.el-table th, .el-table tr{
  background-color: #ffffff;
}
.el-table th, .el-table tr:nth-child(2n){
  background-color: #fff;
}

.header_bg{
  position: relative; /* 添加这一行 */
  height: 80px;
  background-image:url('../../assets/images/top_bj.jpg');
}
</style>
